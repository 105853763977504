import { IconSearch } from "@tabler/icons-react";
import { ReactElement } from "react";
import { SearchBarProps } from "../../types/searchBar";

const SearchBar = ({ onChange }: SearchBarProps): ReactElement => {
  return (
    <div className="search-bar">
      <div className="input-group">
        <IconSearch />
        <input
          onChange={(e) => onChange(e.target.value)}
          type="text"
          placeholder="Recherche"
        />
      </div>
    </div>
  );
};

export default SearchBar;
