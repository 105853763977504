import { IconFilter, IconListDetails } from "@tabler/icons-react";
import { ReactElement, useEffect, useState, useMemo } from "react";
import Blur from "../components/common/Blur";
import Loader from "../components/common/Loader";
import Crafter from "../components/directory/Crafter";
import SearchBar from "../components/directory/SearchBar";
import { displayedTabs } from "../environnment";
import BlockLayer from "../layers/BlockLayer";
import FlexboxLayer from "../layers/FlexboxLayer";
import PageLayer from "../layers/PageLayer";
import { useDirectory } from "../providers/DirectoryProvider";
import { useProfile } from "../providers/ProfileProvider";
import { Roles } from "../roles";
import { filterAllowedTabs } from "../shared/others/filterAllowedTabs";
import { Profile } from "../types/profile";
import { sorts, type Sort } from "../shared/directory/sorts";

const Directory = (): ReactElement => {
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(undefined);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSort, setCurrentSort] = useState<Sort>(sorts.alphabetical);
  const [search, setSearch] = useState<string>("");
  const { profile, roles } = useProfile();
  const { crafters } = useDirectory();

  useEffect(() => {
    setIsDisabled(
      !filterAllowedTabs(roles, displayedTabs).some(
        (tab) => tab.path === "/directory",
      ),
    );
  }, [roles]);

  useEffect(() => {
    if (crafters !== null) {
      setIsLoading(false);

      const availableRoles: string[] = crafters.reduce(
        (acc: string[], crafter: Profile) => {
          const roles = crafter.roles ?? [];

          roles.forEach((role) => {
            if (!acc.includes(role)) {
              acc.push(role);
            }
          });

          return acc;
        },
        [],
      );

      setAvailableRoles(availableRoles);
    }
  }, [crafters]);

  const shownCrafters = useMemo<Profile[] | null>(() => {
    let updatedList = [...(crafters ?? [])];

    if (search.length) {
      updatedList = updatedList.filter(
        (crafter) =>
          crafter.firstname?.toLowerCase().includes(search.toLowerCase()) ||
          crafter.lastname?.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (selectedRoles.length) {
      updatedList = updatedList.filter((crafter) =>
        selectedRoles.some((role) => crafter.roles?.includes(role)),
      );
    }

    return updatedList.sort(currentSort.fn);
  }, [crafters, search, selectedRoles, currentSort]);

  const isAdmin: boolean = useMemo<boolean>(
    () => roles.some((r) => r === Roles.ADMIN),
    [roles],
  );

  const handleSelectRole = (role: string): void => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  if (isDisabled === undefined || isLoading || shownCrafters === null) {
    return <Loader />;
  }

  return (
    <>
      {!isDisabled && isLoading && <Loader />}
      <PageLayer>
        <header>
          <div className="icon">
            <IconListDetails size={55} />
          </div>
          <div className="text">
            <h1 className="page-title">Annuaire</h1>
            <span className="crafters-count">
              {shownCrafters.length} crafter
              {shownCrafters.length > 1 ? "s" : ""}
            </span>
          </div>
        </header>
        <BlockLayer>
          {!isDisabled && (
            <>
              <SearchBar onChange={(e) => setSearch(e)} />
              {isAdmin && (
                <>
                  <div className="input-group">
                    <IconFilter />
                    <span>trier par</span>
                    <select
                      onChange={(e) =>
                        setCurrentSort(
                          sorts[e.target.value as keyof typeof sorts],
                        )
                      }
                    >
                      {Object.values(sorts).map((sort, key) => (
                        <option key={key} value={sort.name}>
                          {sort.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {availableRoles.length > 0 && (
                    <div className="roles-filter">
                      {availableRoles.map((role: string, index: number) => (
                        <span
                          key={index}
                          onClick={() => handleSelectRole(role)}
                          className={`role ${
                            selectedRoles.includes(role) ? "selected" : ""
                          }`}
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <FlexboxLayer>
            {isDisabled && (
              <Blur text="Vous devez souscrire à notre abonnement pour voir cette fonctionnalité !" />
            )}
            {shownCrafters.map((crafter: Profile, index: number) => {
              const isCurrentProfile: boolean =
                crafter.email === profile?.email;
              return (
                <Crafter
                  crafter={isCurrentProfile ? profile : crafter}
                  canEdit={isAdmin}
                  key={index}
                />
              );
            })}
          </FlexboxLayer>
        </BlockLayer>
      </PageLayer>
    </>
  );
};

export default Directory;
