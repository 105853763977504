import {
  IconAlertTriangleFilled,
  IconBuildingEstate,
  IconDoorEnter,
  IconLock,
  IconLockOpen,
  IconPrinter,
  IconRouter,
} from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Blur from "../components/common/Blur";
import CardLoading from "../components/common/CardLoading";
import Date from "../components/common/Date";
import Loader from "../components/common/Loader";
import Toggleable from "../components/common/Toggleable";
import { displayedTabs } from "../environnment";
import printer from "../images/custom/printer.jpg";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";
import { useDoors } from "../providers/DoorsProvider";
import { useProfile } from "../providers/ProfileProvider";
import { formatDoorAction } from "../shared/doors/formatDoorAction";
import { formatDoorStatus } from "../shared/doors/formatDoorStatus";
import { formatDoorStatusCardClass } from "../shared/doors/formatDoorStatusCardClass";
import { filterAllowedTabs } from "../shared/others/filterAllowedTabs";
import { askForConfirmation } from "../shared/popups/askForConfirmation";
import toast from "../shared/popups/toast";
import links from "../static/others/links.json";
import { DoorStatus } from "../types/doors";

const Coworking = (): ReactElement => {
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(undefined);
  const [openToggleable, setOpenToggleable] = useState<string>("access");
  const [doorsLoading, setDoorsLoading] = useState<boolean>(false);
  const { doors, controlDoors } = useDoors();
  const { roles } = useProfile();

  useEffect(() => {
    setIsDisabled(
      !filterAllowedTabs(roles, displayedTabs).some(
        (tab) => tab.path === "/coworking",
      ),
    );
  }, [roles]);

  const handleDoorsAction = async (action: string): Promise<void> => {
    try {
      const isConfirmed: boolean = await askForConfirmation(
        "Êtes vous sûr(e) de vouloir faire ça ?",
      );

      if (isConfirmed) {
        setDoorsLoading(true);
        await controlDoors(action as "lock" | "unlock");
        setDoorsLoading(false);

        const formattedAction: string = formatDoorAction(
          action as "lock" | "unlock",
        );
        toast("success", `Les portes ont été ${formattedAction}ées !`);
      }
    } catch (error: any) {
      setDoorsLoading(false);

      if (error.response.status !== 403) {
        Swal.fire({
          icon: "error",
          title: "Oops ! Une erreur est survenue",
          text: "Une des portes ne répond pas. Veuillez vérifier l'état de la batterie puis réessayer",
          showConfirmButton: false,
          showDenyButton: true,
          denyButtonColor: "#fbbb10",
          denyButtonText: "<span style='color: #282828;'>Fermer</span>",
          focusDeny: false,
          customClass: "popup",
        });
      } else {
        const formattedAction: string = formatDoorAction(
          action as "lock" | "unlock",
        );
        Swal.fire({
          icon: "error",
          title: "Vous ne pouvez pas faire ça !",
          text: `Vous n'avez visiblement pas les permissions pour ${formattedAction}er les portes du QG`,
          showConfirmButton: false,
          showDenyButton: true,
          denyButtonColor: "#fbbb10",
          denyButtonText: "<span style='color: #282828;'>Fermer</span>",
          focusDeny: false,
          customClass: "popup",
        });
      }
    }
  };

  const handleShowPlan = (): void => {
    withReactContent(Swal).fire({
      html: (
        <div className="popup-content">
          <h1>Où sont les locaux ?</h1>
          <iframe
            id="headquarter-position-frame"
            src={links.headquarterPosition}
            className="headquarter-position"
            title="headquarter position"
          ></iframe>
        </div>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      customClass: "popup",
    });
  };

  if (isDisabled === undefined) {
    return <Loader />;
  }

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconBuildingEstate size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Coworking</h1>
          <Date />
        </div>
      </header>
      <BlockLayer>
        {isDisabled && (
          <Blur text="Vous devez souscrire à notre abonnement pour voir cette fonctionnalité !" />
        )}

        <Toggleable
          id="access"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? "open"}
          label="Accès"
          icon={<IconDoorEnter size={30} className="icon" />}
          fadeDelay={100}
        >
          {doorsLoading && <CardLoading />}
          <div className="flex align-center space-between place-header">
            <h2>Où se trouvent nos locaux ?</h2>
            <span>
              Code d&apos;entrée de l&apos;immeuble{" "}
              <span className="value">0269</span>
            </span>
          </div>
          <span className="yellow bold pointer" onClick={handleShowPlan}>
            Voir sur le plan
          </span>

          <div className="doors">
            <div className="buttons">
              <button
                className="primary"
                onClick={() => handleDoorsAction("unlock")}
              >
                Déverrouiller les portes
                <IconLockOpen />
              </button>
              <button
                className="primary"
                onClick={() => handleDoorsAction("lock")}
              >
                Verrouiller les portes
                <IconLock />
              </button>
            </div>
            <div className="statuses">
              {doors && doors.length ? (
                doors.map((status: DoorStatus, key: any) => {
                  const statusCardClass = formatDoorStatusCardClass(
                    status.state,
                  );
                  const doorStatus = formatDoorStatus(status.state);

                  return (
                    <div className={`card status ${statusCardClass}`} key={key}>
                      {status.door?.name} : {doorStatus}
                    </div>
                  );
                })
              ) : (
                <div
                  className="card status other flex align-center"
                  style={{ gap: "10px" }}
                >
                  <IconAlertTriangleFilled />
                  Vous n&apos;avez visiblement pas les permissions de voir le
                  des portes
                </div>
              )}
            </div>
          </div>
        </Toggleable>
        <Toggleable
          id="wifi"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? ""}
          label="Wi-Fi"
          icon={<IconRouter size={30} className="icon" />}
          fadeDelay={200}
        >
          <>
            <h2>Se connecter à notre réseau</h2>
            <br />
            <span className="wifi-prop">
              Nom du réseau
              <span className="value">{"<socraft />"}</span>
            </span>
            <span className="wifi-prop">
              Mot de passe
              <span className="value">crafter-4-EVER</span>
            </span>
          </>
        </Toggleable>
        <Toggleable
          id="printer"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? ""}
          label="Imprimante"
          icon={<IconPrinter size={30} className="icon" />}
          fadeDelay={300}
        >
          <>
            <h2>Utiliser l&apos;imprimante</h2>
            <br />
            <div className="flex align-center printer" style={{ gap: "3em" }}>
              <img
                style={{ borderRadius: "15px 25px" }}
                height="300"
                src={printer}
                alt="Imprimante socraft"
              />
              <div>
                <span>
                  Nom de l&apos;imprimante{" "}
                  <span className="value">
                    HP Color LaserJet MFP M283fdw (FCBB78)
                  </span>
                </span>
                <br />
                <br />
                <strong style={{ opacity: "0.5" }}>
                  Imprimante en libre service, accessible depuis le réseau WiFi.
                </strong>
              </div>
            </div>
          </>
        </Toggleable>
      </BlockLayer>
    </PageLayer>
  );
};

export default Coworking;
