import { Profile } from "../../types/profile";

export interface Sort {
  label: string;
  name: string;
  fn: (a: Profile, b: Profile) => number;
}

type Sorts = {
  alphabetical: Sort;
  entryDate: Sort;
};

export const sorts: Sorts = {
  alphabetical: {
    label: "ordre alphabétique",
    name: "alphabetical",
    fn: (a: Profile, b: Profile) => {
      const aName = `${a.firstname} ${a.lastname}`;
      const bName = `${b.firstname} ${b.lastname}`;

      return aName.localeCompare(bName);
    },
  },
  entryDate: {
    label: "date d'entrée dans la communauté",
    name: "entryDate",
    fn: (a: Profile, b: Profile) => {
      return (
        new Date(b.entryDate ?? 0).getTime() -
        new Date(a.entryDate ?? 0).getTime()
      );
    },
  },
};
